import { render, staticRenderFns } from "./AppView.vue?vue&type=template&id=6956b5d6&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContent } from 'vuetify/lib/components/VContent';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VContent,VFadeTransition})
