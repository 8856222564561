import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './messages.json'
import carrier from './carrier.json'
import privacyPolicy from './privacyPolicy.json'
import prospectus from './prospectus.json'
import securityPolicy from './securityPolicy.json'

Vue.use(VueI18n);

messages.hu.carrier = carrier
messages.hu.privacyPolicy = privacyPolicy.hu
messages.hu.prospectus = prospectus.hu
messages.hu.securityPolicy = securityPolicy.hu
const i18n = new VueI18n({
    locale: 'hu', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
})

export default i18n