import Vue from 'vue'

import Router from 'vue-router'
import Paths from './paths'

Vue.use(Router)

// Function to create routes
// Is default lazy but can be changed
function route (obj) {
  return {
    path: `/${obj.path}`,
    meta: obj.meta,
    component: () => import(
      /* webpackChunkName: "routes" */
      /* webpackMode: "lazy-once" */
      `@/views/${obj.view}.vue`
    )
  }
}

const routes = Paths.map(r => route(r))

routes.push({ path: '*', redirect: '/' })

// Create a new router
const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function() {
    return { x: 0, y: 0 }
  }
})

export default router 
