<template>
  <v-app id="page-top">
    <app-toolbar />
    <div v-bind:class="{ 'new-theme': newTheme }">
      <app-view />
    </div>
    <app-footer />
  </v-app>
</template>

<script>
import AppFooter from '@/components/core/AppFooter';
import AppToolbar from '@/components/core/AppToolbar';
import AppView from '@/components/core/AppView';
import i18n from '@/plugins/i18n';

export default {
  components: {
    AppFooter,
    AppToolbar,
    AppView,
  },
  data() {
    return {
      newTheme: true,
      languages: [
        { flag: 'hu', language: 'hu', title: 'Magyar' },
        { flag: 'en', language: 'en', title: 'English' },
      ],
    };
  },
  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
    },
  },
};
</script>
<style>
.v-application {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 400;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: initial;
  background: #ffffff !important;
}

.v-application .v-icon {
  font-family: 'Roboto', sans-serif;
}

.v-content {
  margin: 0px;
}

.separator {
  height: 1px;
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 10%;
  -webkit-box-direction: normal;
}

.separator-line {
  height: 1px;
  border-top: 1px solid #ebebeb;
  display: block;
  position: relative;
  top: 10px;
  width: 100%;
}

.v-card__title {
  word-break: break-word;
}

.v-responsive__content {
  width: 0px !important;
}

section {
  width: 100%;
}

.new-theme h1,
.new-theme h2,
.new-theme h3,
.new-theme h4,
.new-theme .v-card__title,
.new-theme .v-timeline-item__opposite {
  color: #00408e;
}

.new-theme .v-card.v-sheet,
.v-timeline-item__body > .v-card:not(.v-card--flat):after {
  background-color: #eef5ff;
  border-right-color: #eef5ff;
}

.new-theme .v-timeline-item__body > .v-card:not(.v-card--flat):after {
  background-color: rgba(0, 0, 0, 0);
}

.new-theme .v-timeline-item__dot > .v-timeline-item__inner-dot.primary {
  background-color: #00408e !important;
}
</style>
