<template>
  <div>
    <a href="https://azevirodaja.hu/nevezok/48575" v-if="this.$route.path === '/'" :class="{'top': active.to == null}" class="real-estate-awards">
      <img draggable="false" :alt="$t('realEstateAwards.title')" :src="require('@/assets/' + $t('realEstateAwards.logo'))"/>
    </a>
    <v-app-bar height="56" fixed :dark="active.to == null" :light="active.to != null" class="pa-0">
      <v-toolbar-items width="100%" v-if="$vuetify.breakpoint.width > 800 && !$vuetify.breakpoint.xs">
        <a @click="navigateHome()">
          <img draggable="false" class="logo" alt="Logo" v-if="active.to == null" src="../../assets/logow_white.png"/>
          <img draggable="false" class="logo" alt="Logo" v-else src="../../assets/logob.png"/>
        </a>
        <v-btn v-for="(item, i) in items" :key="i"
              draggable="false"
              :active-class="item.id == active.id ? 'v-btn--active' : 'no-active'"
              @click="setActive(item)"
              text min-width="0"
              to="/">
          <span v-text="item.text" />
        </v-btn>
        <v-btn 
              draggable="false"
              :active-class="'no-active'"
              text min-width="0"
              href="https://karrier.smpsolutions.hu">
          <span>{{ $t('menu.career') }}</span>
        </v-btn>
        <v-spacer></v-spacer>

        <v-scale-transition v-for="entry in languages" :key="entry.title">
        <v-btn text max-width="50" min-width="0"
              @click="changeLocale(entry.language)">
          {{entry.flag}}
        </v-btn>
        </v-scale-transition>
      </v-toolbar-items>
      <v-toolbar-items class="mobile-menu" v-else>
        <a @click="navigateHome()">
          <img draggable="false" class="logo" alt="Logo" v-if="active.to == null" src="../../assets/logow_white.png"/>
          <img draggable="false" class="logo" alt="Logo" v-else src="../../assets/logob.png"/>
        </a>
        <span class="header-label" v-text="active.text" />
        <v-menu offset-y left text :dark="active == '/'" :light="active != '/'" v-model="menu">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" large>{{menu ? 'mdi-menu-open' : 'mdi-menu'}}</v-icon>
          </template>
          <v-list>
            <v-list-item v-for="(item, i) in items" :key="i">
              <v-btn :active-class="item.id == active ? 'v-btn--active' : 'no-active'"
                     text class="pa-0" min-width="0" @click="setActive(item)" to="/">
                {{ item.text }}
              </v-btn>
            </v-list-item>

            <v-btn 
              draggable="false"
              :active-class="'no-active'"
              text min-width="0"
              href="https://karrier.smpsolutions.hu">
              <span>{{ $t('menu.career') }}</span>
            </v-btn>

            <v-list-item width="50">
              <v-btn v-for="entry in languages" :key="entry.title"
                  text class="pa-0"
                  @click="changeLocale(entry.language)">{{entry.flag}}</v-btn>
              </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'

export default {
  data: () => ({
    active: {},
    menu: false,
    isScrolling: false,
    languages: [
      { flag: 'hu', language: 'hu', title: 'Magyar' },
      { flag: 'en', language: 'en', title: 'English' }
    ]
  }),
  created: function () {
    const self = this
    let path
    if (this.$route.hash !== window.location.hash.replace('#', '')) {
      path = window.location.hash.replace('#', '')
    } else {
      path = this.$route.path
    }

    const active = this.items.filter((item) => item.to == path)
    if (active.length > 0) {
      this.setActive(active[0])
    }
    window.addEventListener('scroll', function () {
      const position = (window.pageYOffset || document.documentElement.scrollTop || 0)
      const sections = self.items.filter((item) => {
        const elem = document.getElementById(item.id)
        return elem != null && elem.offsetTop + document.getElementById('home').offsetHeight < position + 56
      })
      self.active = sections.length > 0 ? sections[sections.length - 1] : {}
    });
  },
  computed: {
    items() {
      let items = this.$t('toolbar').filter((item) => item.to != '/home')
      for (const item of items) {
        let path = item.to.split('/')
        item.id = path[1]
      }
      return items
    }
  },
  methods: {
    navigateProject() {
      if (this.$route.path !=='/') {
        this.$router.push('/')
      }
      if (this.active.id != 'project') {
        i18n.locale = 'hu'
        this.setActive({id: 'project'})
      }
    },
    navigateHome() {
      this.active = {}
      if (this.$route.path !=='/') {
        this.$router.push('/')
      } else {
        this.$vuetify.goTo('#home', {offset: 56})
      }
    },
    changeLocale(locale) {
      if (i18n.locale == locale) {
        return
      }
      i18n.locale = locale
      const menuItem = this.$t('toolbar').filter((item) => item.to == this.active.to)
      if (this.$route.path !=='/') {
        this.$router.push('/')
      }
      setTimeout(() => {
        if (menuItem.length == 0) {
          this.active = {}
          this.$vuetify.goTo('#home', {offset: 56})
        } else {
          this.$vuetify.goTo('#' + menuItem[0].id, {offset: 56})
        }
      }, 500)
    },
    setActive(item) {
      setTimeout(() => {
        this.active = item
        this.$vuetify.goTo('#' + (item.id == '' ? 'page-top' : item.id), {offset: 56})
      }, 500)
    }
  }
}
</script>

<style>
  header {
    z-index: 3;
  }

  .logo {
    max-height: 40px;
    margin: 5px;
  }

  .real-estate-awards {
    z-index: 10;
    position: fixed;
    left: 20px;
    top: 70px;
    opacity: 0.85;
  }

  .real-estate-awards img {
    height: 80px;
    opacity: 0.85;
    transition: height 1s ease-out;
  }

  .real-estate-awards.top img {
    height: 140px;
    opacity: 1;
  }

  .mobile-menu {
    justify-content: space-between;
  }

  header .v-toolbar__items {
    width: 100%;
  }

  header .v-toolbar__items .v-btn {
    padding: 0 7px !important;
    font-size: 12px;
  }

  .header-label {
    text-transform: uppercase;
    padding: 18px 5px;
  }
  
  .project_logo {
    z-index: 10;
    position: fixed;
    right: 0;
    top: 0;
  }

  .no-active::before {
    opacity: 0 !important;
  }

  @media only screen and (max-width: 500px)  {
    .header-label {
      display: none;
    }
  }

  @media only screen and (max-width: 400px)  {
    .logo {
      max-width: 50px;
      margin: 15px 0 0 -10px;
    }
  }
  @media only screen and (max-width: 300px)  {
    .logo {
      display: none;
    }
  }
</style>
