<template>
  <v-footer dark>
    <v-card style="background: transparent" width="100%">
      <v-card-text class="white--text pa-0">
        <v-container class="pa-0">
          <v-row :align="'center'">
            <v-col md="4" sm="3" cols="12" class="pa-5">
              &copy; 2023
              {{ $t('company') }}
              <br />
              {{ $t('rights') }}
            </v-col>
            <v-col md="4" offset-md="4" sm="9" cols="12" offset-sm="0" class="pa-0">
              <p class="text-right ma-1">
                <img alt="certop" class="certop-img" src="../../assets/certop.png" />
                <img alt="iso" class="ml-5"  src="../../assets/iso-1.png" />
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  methods: {
    openPdfInNewTab(pdfUrl) {
      window.open(pdfUrl, '_blank');
    },
  },
};
</script>

<style scoped>
.v-footer {
  min-height: 112px;
}
.v-footer .v-card {
  box-shadow: none !important;
}
.container {
  max-width: 100% !important;
}
.certop-img {
  height: 90px;
}
</style>
